function getVimeoApiUrl (videoId) {
    const videoUrl = `http://vimeo.com/${videoId}`
    return `http://vimeo.com/api/oembed.json?url=${encodeURIComponent(videoUrl)}&callback=embedVimeoVideo&width=523&height=348`
}

export default () => {
    const players = document.querySelectorAll('[data-video-player]')
    if (players.length > 0) {
        const youtubePlayers = document.querySelectorAll('[data-video-player][data-type="youtube"]')
        const vimeoPlayers = document.querySelectorAll('[data-video-player][data-type="vimeo"]')

        if (youtubePlayers.length > 0) {
            window.onYouTubePlayerAPIReady = () => {
                youtubePlayers.forEach(player => {
                    const ytPlayer = new YT.Player(player.id, {
                        height: '348',
                        width: '523',
                        videoId: player.dataset.url
                    })
                })
            }
            const tag = document.createElement('script')
            tag.src = 'https://www.youtube.com/iframe_api'
            const firstScriptTag = document.getElementsByTagName('script')[0]
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        }

        if (vimeoPlayers.length > 0) {
            vimeoPlayers.forEach(player => {
                const url = getVimeoApiUrl(player.dataset.url)
                fetch(url)
            })

            window.embedVimeoVideo = function (video) {
                document.querySelector(`[data-video-player][data-url="${video.video_id}"]`).innerHTML = video.html
            }
        }
    }
}
