import 'es-module-shims'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'instant.page'
import 'iconify-icon'

import { onDocumentReady } from './src/onDocumentReady'
import naja from 'naja'
import LazyLoad from 'vanilla-lazyload'
import { stickyHeader } from './src/stickyHeader'
import videoLoader from './src/videoLoader'
import { teachersListInit } from '../teachersList/teachersList'

onDocumentReady(() => {
    document.body.classList.add('site-onready')
    naja.initialize()
    const cocoboxScripts = {
        lazyLoadInstance: new LazyLoad({})
    }
    cocoboxScripts.lazyLoadInstance.update()
    window.cocoboxScripts = cocoboxScripts

    document.getElementById('__navbarToggler').addEventListener('click', () => {
        if (document.body.classList.contains('mobile-menu-open')) {
            document.body.classList.remove('mobile-menu-open')
            return
        }
        document.body.classList.toggle('mobile-menu-open')
    })

    stickyHeader()
    videoLoader()
    if (document.querySelector('#teachersListInput')) {
        teachersListInit()
    }
})
