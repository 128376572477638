import Jets from 'jets'

export function teachersListInit () {
    try {
        const modal = document.getElementById('teacherModal')
        const targets = document.querySelectorAll('[data-teacher-data]')
        targets.forEach((element) => {
            element.addEventListener('click', (event) => {
                const data = JSON.parse(event.target.dataset.teacherData)
                data.timetableId = event.target.dataset.timetableId || '1'
                document.getElementById('teacherModalTitle').innerText = data.displayName
                document.getElementById('teacherModalBody').innerHTML = `
                    <div class="p-3">
                        <div class="row">
                            <div class="col-5 col-md-3 col-lg-4">
                                <img
                                    src="${data.photoSrc || '/uploads/layout/teacher-placeholder.webp'}"
                                    alt="${data.displayName}"
                                    class="img img-fluid object-fit-cover w-100 h-100"
                                />
                            </div>

                            <div class="col-7 col-md-9 col-lg-8 ps-md-3">
                                <p class="fw-bold text-gml-blue mb-2 fs-5">
                                    ${data.displayName}
                                </p>
                                <ul class="d-flex flex-wrap list-unstyled">
                                    ${(
                                        (data.email)
                                        ? `
                                            <li class="w-100">
                                                <iconify-icon class="text-gml-blue me-1" icon="bi:envelope" inline="true"></iconify-icon>
                                                <a href="mailto:${data.email}" target="_self">${data.email}</a>
                                            </li>
                                        `
                                        : ''
                                    )}
                                    ${(
                                        (data.phone)
                                        ? `
                                            <li class="w-100">
                                                <iconify-icon class="text-gml-blue me-1" icon="bi:telephone" inline="true"></iconify-icon>
                                                ${data.phone}
                                            </li>
                                        `
                                        : ''
                                    )}
                                    <li class="w-100">
                                        <iconify-icon class="text-gml-blue me-1" icon="bi:clock" inline="true"></iconify-icon>
                                        <a href="https://gmlbrno.edupage.org/timetable/view.php?num=${data.timetableId}&teacher=${data.edupageId}" target="_blank" rel="noopener noreferrer">
                                            rozvrh hodin
                                        </a>
                                    <li/>
                                    ${(
                                        (data.storageLink)
                                        ? `
                                            <li class="w-100">
                                                <iconify-icon class="text-gml-blue me-1" icon="bi:cloud-arrow-up" inline="true"></iconify-icon>
                                                <a href="${data.storageLink}" target="_blank" rel="noopener noreferrer">
                                                    osobní stránky
                                                </a>
                                            </li>
                                        `
                                        : ''
                                    )}
                                </ul>
                                ${(
                                    (data.className || data.subjects || data.cabinetLocation)
                                    ? `
                                        <h5 class="mt-4 mb-2 fw-bold text-gml-blue">
                                            Další informace
                                        </h5>
                                        <ul class="d-flex flex-wrap list-unstyled">
                                            ${(
                                                (data.className)
                                                ? `
                                                    <li class="w-100 fw-bold">
                                                        Třídní učitel pro třídu ${data.className}
                                                    </li>
                                                `
                                                : ''
                                            )}
                                            ${(
                                                (data.subjects)
                                                ? `
                                                    <li class="w-100">
                                                        Vyučované předměty: ${data.subjects}
                                                    </li>
                                                `
                                                : ''
                                            )}
                                            ${(
                                                (data.cabinetLocation)
                                                ? `
                                                    <li class="w-100">
                                                        Umístění kabinetu: ${data.cabinetLocation}
                                                    </li>
                                                `
                                                : ''
                                            )}
                                        </ul>
                                    `
                                    : ''
                                )}
                            </div>
                        </div>
                `
            })
        })

        const jets = new Jets({
            searchTag: '#teachersListInput',
            contentTag: '.teachers-list',
            manualContentHandling: (tag) => {
                return `${tag.dataset.teacherSearch}`
            },
            addImportant: true
        })
    } catch (error) {
        console.error(error)
    }
}
