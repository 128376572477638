export function stickyHeader () {
    let tm
    let prevPosition = 0
    let position = 0

    function checkStickyMenu() {
        position = window.scrollY
        const body = document.body
        const isActive = body.classList.contains('sticky-header')
        if (position > 175) {
            if (!isActive) {
                body.classList.add('sticky-header-no-transition')
                setTimeout(function() {
                    body.classList.remove('sticky-header-no-transition')
                }, 50)
                body.classList.add('sticky-header')
            } else {
                const difference = position - prevPosition
                if (difference <= -10) {
                    body.classList.add('sticky-header-up')
                } else if (difference >= 20) {
                    body.classList.remove('sticky-header-up')
                }
            }
        } else {
            body.classList.remove('sticky-header-no-transition', 'sticky-header-up', 'sticky-header')
        }
        prevPosition = position
    }

    document.addEventListener('scroll', function (event) {
        clearTimeout(tm)
        tm = setTimeout(checkStickyMenu, 100)
    })

    checkStickyMenu()
}
